import { useEffect, useState } from 'react'
import Image from 'next/image'

/* blocks */
import Link from '@/atoms/Link/Link'

/* utils */
import {
  getPromotionalBannerVisibility,
  setPromotionalBannerVisibility
} from 'lib/session-storage'

function PromotionalBanner() {
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    const bannerStatus = getPromotionalBannerVisibility()

    if (bannerStatus !== 'hide') {
      setShowBanner(true)
    }
  }, [])

  const handleClose = () => {
    setShowBanner(false)
    setPromotionalBannerVisibility('hide')
  }

  if (!showBanner) return null

  return (
    <section
      id="top-banner"
      className="bg-gradient-to-b from-[#FFFFFF] to-[#FFE599] | sticky top-0 left-0 z-[100] | w-full | py-5"
    >
      <div className="container | flex items-center justify-between gap-4">
        <div className="text-sm md:text-base text-center | w-full mx-auto">
          Check out our newsletter for marketers who believe the future of
          content is human + AI.{' '}
          <Link
            href="https://peppercontentinc.substack.com/?utm_source=website&utm_medium=strip"
            target="_blank"
            className="text-[#341FFF] underline underline-offset-2"
          >
            Read More
          </Link>
        </div>
        <button onClick={handleClose} title="Close">
          <Image
            src="/assets/cmp/icons/close-circle.svg"
            alt="Cross Icon"
            width={30}
            height={30}
            className="w-6 h-6 md:w-8 md:h-8"
          />
        </button>
      </div>
    </section>
  )
}

export default PromotionalBanner
