import { mixed, InferType } from 'yup'
import { MixedSchema } from 'yup/lib/mixed'

const SESSION_STORAGE_KEYS = {
  PROMOTIONAL_BANNER_VISIBILITY: 'PROMOTIONAL_BANNER_VISIBILITY'
}

const PromotionalBannerVisibilitySchema = mixed().oneOf([
  'show',
  'hide'
]) as MixedSchema<'show' | 'hide'>
type PromotionalBannerVisibilityType = InferType<
  typeof PromotionalBannerVisibilitySchema
>

export const setPromotionalBannerVisibility = (
  val: PromotionalBannerVisibilityType
) => {
  sessionStorage.setItem(
    SESSION_STORAGE_KEYS.PROMOTIONAL_BANNER_VISIBILITY,
    val
  )
}

export const getPromotionalBannerVisibility =
  (): PromotionalBannerVisibilityType | null => {
    const val = sessionStorage.getItem(
      SESSION_STORAGE_KEYS.PROMOTIONAL_BANNER_VISIBILITY
    ) as PromotionalBannerVisibilityType | null

    return val
  }
