import { useEffect, useRef } from 'react'

// * * similar to useEffect hook but only runs on dependency update and not on component mount
const useUpdateEffect: typeof useEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useUpdateEffect
