import Link from '@/atoms/Link/Link'
import clsx from 'clsx'
import { dataLayerFooterClickEvent } from 'src/utils/functions/dataLayerEvents'

const extraFooterLinks: FooterLink[] = [
  { page_title: 'Terms of Service', slug: '/terms/' },
  { page_title: 'Privacy Policy', slug: '/privacy/' },
  { page_title: 'Sitemap', slug: '/sitemap/' }
]

const FooterRights = (): JSX.Element => {
  const textColorClass = 'text-[#B1ADD8]'
  return (
    <section className="container lg:flex | px-5 py-6 sm:px-10 lg:px-6 mx-auto lg:space-x-8 ">
      <p className={clsx('text-small inline-block | mb-2 ', textColorClass)}>
        © {new Date().getFullYear()}, Pepper Content Inc. All Rights Reserved.
      </p>
      <ul className="lg:flex lg:space-x-8">
        {extraFooterLinks.map(link => (
          <li key={link.slug} className="text-small  mb-2  list-none">
            <Link
              className={clsx(
                'inline-block hover:text-blue-50 focus:text-blue-50 focus:outline-none',
                textColorClass
              )}
              href={link.slug}
              onClick={() => {
                dataLayerFooterClickEvent({
                  navigationMenuName: link.page_title
                })
              }}
            >
              {link.page_title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default FooterRights
