import { AnchorHTMLAttributes } from 'react'
import NextLink from 'next/link'

interface Props extends AnchorHTMLAttributes<unknown> {
  scroll?: boolean
  as?: string
  shallow?: boolean
  // TODO: temp prop, pass true for gatsby pages
  external?: boolean
}

function Link({
  children,
  href,
  className,
  scroll,
  shallow,
  external = false,
  ...other
}: Props) {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Next)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^(\?|\/)(?!\/)/.test(href ?? '')
  if (internal && href && !external) {
    return (
      <NextLink
        href={href}
        scroll={scroll}
        shallow={shallow}
        className={className}
        {...other}
      >
        {children}
      </NextLink>
    )
  }
  return (
    <a href={href} className={className} {...other}>
      {children}
    </a>
  )
}
export default Link
